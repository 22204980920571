/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "gatsby";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Settings, Group, Phone, Mail, Room} from "@material-ui/icons";


// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";





function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Services"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Settings}
          dropdownList={
            services.map((service, index) => {
              return (
                <Link key = {index} to= {service.url} className={classes.dropdownLink}>
                  {service.service}
                </Link>
              )
            })
          }
        />
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Link
          to= '/#about'
          color="transparent"
          className={classes.navLink}
        >
          <Group className={classes.icons} /> About
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to= '/#services'
          color="transparent"
          className={classes.navLink}
        >
          <Settings className={classes.icons} /> Services
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="phone"
          title="Give us a Call"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="tel:0457520201"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <Phone/><p className= "contact-text">Call</p>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="email"
          title="Send us an Email"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="mailto:neville@afterglowservices.com.au"
            target="_blank"
            className={classes.navLink}
          >
            <Mail/><p className= "contact-text">Message</p>
          </Button>
        </Tooltip>
      </ListItem>

      <ListItem className={classes.listItem}>
          <Button
            className= "nav-btn"
            color="success"
            href="/#contact"
          >
            Get a Free Quote
          </Button>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
