import React from "react";
import {useState, useEffect} from 'react'

import Header from './Header/Header'
import HeaderLinks from './Header/HeaderLinks'
import LogoLink from './Header/LogoLink'
import logo from '../assets/images/Afterglow-logo-color.png'
import whitelogo from '../assets/images/Afterglow-logo-mono.png'

import Footer from "components/Footer/Footer.jsx";

import "../assets/css/main.css"
import "../assets/css/global.css"
import Navbar from "./Navbar";




const Layout = ({children}) => {

  // const [scroll, setScroll] = useState(0);

  // function handleScroll() {
  //   setScroll(window.pageYOffset);
  // }

  // useEffect(() => {
  //   function watchScroll() {
  //     window.addEventListener("scroll", handleScroll);
  //   }

  //   watchScroll();
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // });

  return (
    <>
        {/* <Header
        brand = {<LogoLink logo = {(scroll > 100) ? logo : whitelogo}/>}
        rightLinks= {<HeaderLinks/>}
        fixed
        color= "transparent"
        changeColorOnScroll= {{
          height: 100,
          color: "white"
        }}
        /> */}
        <div className="fixed w-full z-10">
        <Navbar/>
        </div>
        {children}
        <Footer/>

    </>
  )
}

export default Layout
